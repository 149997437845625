<template>
  <el-main>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="自营服务" name="1">
        <el-form class="el-form-search" label-width="90px">
          <el-form-item label="服务名称：">
            <el-input v-model="searchForm1.service_name" size="small" placeholder="请输入服务名称"></el-input>
          </el-form-item>
          <el-form-item label="服务分类：">
            <el-cascader
              v-model="searchForm1.classify_id"
              :props="{ value: 'id', label: 'name', children: '_child' }"
              placeholder="请选择或搜索"
              filterable
              :options="goodsClass"
              size="small"
            ></el-cascader>
          </el-form-item>
          <el-form-item label=" " label-width="20px">
            <el-button size="small" @click="search1" type="primary">搜索</el-button>
            <el-button size="small" @click="searchClear1" type="text">清除搜索条件</el-button>
          </el-form-item>
        </el-form>
        <div class="shop-info">
          <el-image :src="shop_logo"></el-image>
          <div>{{ shop_name }}</div>
        </div>
        <el-table :data="shopGoodsList" :header-cell-style="{ 'background-color': '#F8F9FA' }">
          <template slot="empty">
            <No />
          </template>
          <el-table-column label="服务主图" align="center" width="80px">
            <template v-slot="{ row }">
              <el-image style="width: 60px; height: 60px" :src="row.service_cover_picture ? row.service_cover_picture : require('@/assets/default-img.png')"></el-image>
            </template>
          </el-table-column>
          <el-table-column prop="classify_name" label="服务分类" align="center"></el-table-column>
          <el-table-column prop="service_name" label="服务名称" align="center"></el-table-column>
          <el-table-column label="服务价格" align="center">
            <template v-slot="{ row }">{{ row.service_price }}</template>
          </el-table-column>
          <el-table-column prop="sales" label="销量" align="center"></el-table-column>
          <el-table-column label="操作" fixed="right" align="center" width="200px">
            <template v-slot="{ row }">
              <el-button type="text" @click="soldOut(row.id)">下架</el-button>
              <el-button type="text" @click="$router.push('/gateway/service/serviceEdit?id=' + row.id)">查看详情</el-button>
            </template>
          </el-table-column>
        </el-table>
        <Paging :total="total_number1" :page="searchForm1.page" :pageNum="searchForm1.rows" @updatePageNum="updateData1"></Paging>
      </el-tab-pane>
      <el-tab-pane label="代销服务" name="2">
        <el-form class="el-form-search" label-width="110px">
          <el-form-item label="服务名称：">
            <el-input v-model="searchForm2.goods_name" size="small" placeholder="请输入服务名称"></el-input>
          </el-form-item>
          <el-form-item label="服务分类：">
            <el-cascader size="small" clearable :props="{ value: 'id', label: 'name', children: '_child' }" v-model="searchForm2.classify_id" :options="goodsClass"></el-cascader>
          </el-form-item>
          <el-form-item label="服务来源：">
            <el-select v-model="searchForm2.source" size="small">
              <el-option label="全部" :value="0"></el-option>
              <el-option label="平台服务" :value="-1"></el-option>
              <el-option label="会员服务" :value="1"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="来源店铺名称：">
            <el-input v-model="searchForm2.enterprise_name" size="small" placeholder="请输入来源店铺名称"></el-input>
          </el-form-item>
          <el-form-item label="来源店铺电话：">
            <el-input v-model="searchForm2.mobile" size="small" placeholder="请输入来源店铺电话："></el-input>
          </el-form-item>
          <el-form-item label=" " label-width="20px">
            <el-button size="small" @click="search2" type="primary">搜索</el-button>
            <el-button size="small" @click="searchClear2" type="text">清除搜索条件</el-button>
          </el-form-item>
        </el-form>
        <div class="shop-info">
          <el-image :src="shop_logo"></el-image>
          <div>{{ shop_name }}</div>
        </div>
        <el-table :data="consignmentGoods" :header-cell-style="{ 'background-color': '#F8F9FA' }">
          <template slot="empty">
            <No />
          </template>
          <el-table-column label="服务主图" align="center" width="80px">
            <template v-slot="{ row }">
              <el-image style="width: 60px; height: 60px" :src="row.service_cover_picture ? row.service_cover_picture : require('@/assets/default-img.png')"></el-image>
            </template>
          </el-table-column>
          <el-table-column prop="classify_name" label="服务分类" align="center"></el-table-column>
          <el-table-column prop="service_name" label="服务名称" align="center"></el-table-column>
          <el-table-column label="服务价格" align="center">
            <template v-slot="{ row }">{{ row.service_price }}</template>
          </el-table-column>
          <el-table-column prop="sales" label="销量" align="center"></el-table-column>
          <el-table-column prop="member_ids" label="服务来源" align="center">
            <template v-slot="{ row }">{{ row.member_ids == 0 ? '平台服务' : '会员服务' }}</template>
          </el-table-column>
          <el-table-column prop="enterprise_name" label="来源店铺名称" align="center"></el-table-column>
          <el-table-column prop="mobile" label="来源店铺电话" align="center"></el-table-column>
          <el-table-column label="操作" fixed="right" align="center" width="200px">
            <template v-slot="{ row }">
              <el-button type="text" @click="soldOut(row.id)">下架</el-button>
              <el-button type="text" @click="$router.push('/gateway/service/serviceEdit?id=' + row.id)">查看详情</el-button>
            </template>
          </el-table-column>
        </el-table>
        <Paging :total="total_number2" :page="searchForm2.page" :pageNum="searchForm2.rows" @updatePageNum="updateData2"></Paging>
      </el-tab-pane>
    </el-tabs>
    <el-dialog title="下架" :visible.sync="showSoldOut" width="650px">
      <el-form>
        <el-form-item label="请添加下架原因：">
          <el-input v-model="remark" type="textarea" :rows="5"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showSoldOut = !1">取 消</el-button>
        <el-button type="primary" @click="confirmSoldOut">确 定</el-button>
      </span>
    </el-dialog>
  </el-main>
</template>

<script>
import Paging from '@/components/paging';
export default {
  components: {
    Paging,
  },
  data() {
    return {
      activeName: '1',
      member_id: '',
      id: '',
      shop_logo: '',
      shop_name: '',
      searchForm1: {
        page: 1,
        rows: 10,
        classify_id: '',
        service_name: '',
      },
      searchForm2: {
        page: 1,
        rows: 10,
        classify_id: '',
        service_name: '',
        source: 0,
        enterprise_name: '',
        mobile: '',
      },
      total_number1: 0,
      total_number2: 0,
      goodsClass: [],
      shopGoodsList: [],
      consignmentGoods: [],
      showSoldOut: !1,
      remark: '',
    };
  },
  created() {
    this.$axios.post(this.$api.gateway.service.ServiceClassify).then(res => {
      if (res.code === 0) {
        this.goodsClass = res.result;
      }
    });
    this.member_id = this.$route.query.id;
    this.shop_logo = this.$route.query.shop_logo;
    this.shop_name = this.$route.query.shop_name;
    this.getShopGoodsList();
    this.getConsignmentGoods();
  },
  methods: {
    updateData1(val, status) {
      if (status == 0) {
        this.searchForm1.rows = val;
      } else {
        this.searchForm1.page = val;
      }
      this.getShopGoodsList();
    },
    updateData2(val, status) {
      if (status == 0) {
        this.searchForm2.rows = val;
      } else {
        this.searchForm2.page = val;
      }
      this.getConsignmentGoods();
    },
    search1() {
      this.searchForm1.page = 1;
      this.getShopGoodsList();
    },
    search2() {
      this.searchForm1.page = 1;
      this.getConsignmentGoods();
    },
    searchClear1() {
      this.searchForm1 = {
        page: 1,
        rows: 10,
        classify_id: '',
        goods_name: '',
      };
      this.getShopGoodsList();
    },
    searchClear2() {
      this.searchForm2 = {
        page: 1,
        rows: 10,
        classify_id: '',
        goods_name: '',
        source: 0,
        enterprise_name: '',
        mobile: '',
      };
      this.getConsignmentGoods();
    },
    soldOut(id) {
      this.id = id;
      this.remark = '';
      this.showSoldOut = !0;
    },
    confirmSoldOut() {
      if (!this.remark) return this.$message.warning('请填写下架原因');
      this.$axios
        .post(this.$api.gateway.member.soldServiceOut, {
          member_id: this.member_id,
          id: this.id,
          remark: this.remark,
        })
        .then(res => {
          if (res.code == 0) {
            this.showSoldOut = !1;
            this.$message.success('下架成功');
            this.getShopGoodsList();
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    getShopGoodsList() {
      this.$axios
        .post(this.$api.gateway.member.shopService, {
          ...this.searchForm1,
          member_id: this.member_id,
        })
        .then(res => {
          if (res.code == 0) {
            this.shopGoodsList = res.result.list;
            this.total_number1 = res.result.total_number;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    getConsignmentGoods() {
      this.$axios
        .post(this.$api.gateway.member.consignmentService, {
          ...this.searchForm2,
          member_id: this.member_id,
        })
        .then(res => {
          if (res.code == 0) {
            this.consignmentGoods = res.result.list;
            this.total_number2 = res.result.total_number;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
  .shop-info {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    .el-image {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      margin-right: 10px;
    }
  }
}
</style>